import React, { useContext, useEffect, useState } from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  OfficeBuildingIcon
} from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { getuserprofile } from "../lib/api";
import {
  ArrowLeftIcon,
  MailIcon,
  MailOpenIcon
} from "@heroicons/react/outline";

const UserInfo = (props) => {
  const { state, dispatch } = useContext(AppContext);
  const { route, showattest, backbutton } = props;
  const [isLoading, setLoading] = useState(false);

  const Loadprofile = async () => {
    let post_params = {};
    setLoading(true);
    const response = await getuserprofile(post_params);
    const dataset = await response;
    if (dataset) {
      dispatch({
        type: "LOGGEDIN_USER",
        response: dataset
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    Loadprofile();
  }, []);

  return (
    <div className="bg-white shadow w-full">
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            {/* Profile */}
            <div className="flex items-center">
              {isLoading === false && (
                <img
                  className="hidden h-16 w-16 rounded-full sm:block"
                  src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                  alt=""
                />
              )}
              <div>
                {isLoading ? (
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    Please Wait...
                  </h1>
                ) : (
                  <div className="flex items-center">
                    <img
                      className="h-16 w-16 rounded-full sm:hidden"
                      src="https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"
                      alt=""
                    />
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      {state?.user?.welcome}, {state?.user?.name}
                    </h1>
                  </div>
                )}
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                  <dt className="sr-only">Company</dt>
                  <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6">
                    <OfficeBuildingIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {state?.user?.referenceno
                      ? state?.user?.referenceno
                      : state?.user?.contactno}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="ml-3 h-6 w-6 flex-shrink-0 mr-1.5  text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                      />
                    </svg>
                    {state?.user?.email ? state?.user?.email : "No E-mail"}
                  </dd>
                  <dt className="sr-only">Account status</dt>
                  <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                    <MailIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    {"SMS BAL: "}
                    <span className="font-bold text-md text-green-800">
                      &nbsp;{state?.user?.usercredit?.total_sms}
                    </span>
                  </dd>
                </dl>
              </div>
            </div>
          </div>
          {route === "home" && (
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <Link to="/profile">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-purple-100 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  {state?.user?.verified === "Y" ? "Profile" : "Update Profile"}
                </button>
              </Link>
              <Link to="/newmessage">
                <button
                  type="button"
                  //disabled={state?.user?.verified === "Y" ? false : true}
                  className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  New message
                </button>
              </Link>
            </div>
          )}
        </div>
        {backbutton && (
          <button
            type="button"
            class="flex text-gray-900 bg-gradient-to-r from-teal-200 to-lime-200 hover:bg-gradient-to-l hover:from-teal-200 hover:to-lime-200 focus:ring-4 focus:outline-none focus:ring-lime-200 dark:focus:ring-teal-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
          >
            <ArrowLeftIcon
              className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
              aria-hidden="true"
            />{" "}
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default UserInfo;
