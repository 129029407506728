import React, { useCallback, useState, useEffect } from "react";
import UserInfo from "./UserInfo";

export const Recharge = () => {
  return (
    <>
      <div className="overflow-x-hidden">
        <UserInfo />
        <div className="md:grid md:grid-cols-2 md:gap-6  ml-6 mr-6 mt-3">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Top Up
              </h3>
              <p className="mt-1 text-sm text-gray-600">Unavailabe</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
