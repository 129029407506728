import { useEffect, useContext, useState } from "react";
import { CashIcon, ChevronRightIcon, TagIcon } from "@heroicons/react/solid";
import { AppContext } from "../context/AppContext";
import { Link } from "react-router-dom";
import UserInfo from "../components/UserInfo";
import { getcases, getuserprofile } from "../lib/api";
import { CheckCircleIcon, FolderIcon } from "@heroicons/react/outline";

const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800"
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Home() {
  const { state, dispatch } = useContext(AppContext);

  return (
    <>
      <div className="min-h-full">
        <div className="w-full">
          <main className="flex-1 pb-8">
            <UserInfo state={state} route="home" showattest={true} />

            <div className="mt-8">
              {/*  <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
                Welcome to Our portal
              </h2> */}
              <div className="rounded-md bg-green-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <CheckCircleIcon
                      className="h-5 w-5 text-green-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-green-800">
                      Notice Board
                    </h3>
                    <div className="mt-2 text-sm text-red-700">
                      <p>
                        Recharge is not available at the momment, due to the
                        technical issues
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
