import React, { useContext, useEffect, useState } from "react";

import { CalendarIcon, MapIcon } from "@heroicons/react/solid";
import { getsessions } from "../lib/api";
import { CurrencyDollarIcon } from "@heroicons/react/outline";

export default function Sessions() {
  const [data, setval] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const loadsession = async () => {
    let post_params = {};
    setLoading(true);
    const response = await getsessions(post_params);
    const dataset = await response;
    if (dataset) {
      setval(dataset);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadsession();
  }, []);

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data?.length <= 0 && isLoading === false && (
          <h2 className="mt-4">No Sessions to display</h2>
        )}
        {data?.map((row, Index) => (
          <li key={Index}>
            <a href="#" className="block hover:bg-gray-50">
              <div className="px-4 py-4 sm:px-6">
                <div className="flex items-center justify-between">
                  <p className="text-sm font-medium text-indigo-600 truncate">
                    {row.browser}
                  </p>
                  <div className="ml-2 flex-shrink-0 flex">
                    <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {row.reference}
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500 font-bold">
                      <MapIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {row.ip}
                    </p>
                  </div>
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <CalendarIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <p>
                      Expire{" "}
                      <time dateTime={row.expiredate}>{row.expiredate}</time>
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}
