import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

import axios from "axios";
import { Link } from "react-router-dom";

const baseURL = process.env.REACT_APP_API_ENDPOINT;
export default function Login() {
  const [getResult, setGetResult] = useState(null);
  const [val, setval] = useState({});

  const ShowNotitication = (data, id) => {
    toast.update(id, {
      render: data?.message ? data?.message : "Something is missing here!!",
      type: data?.success ? "success" : "error",
      isLoading: false,
      autoClose: 2000,
      theme: "colored"
    });
    if (data.success) window.location.href = "/";
    //toast.dismiss(toastId.current);
  };

  const handleclick = () => {
    const data = new FormData();
    data.append("username", val.username);
    data.append("password", val.password);
    const id = toast.loading("Please wait...");
    axios
      .post(`${baseURL}/auth`, data)
      .then((res) => {
        if (res?.data?.token) {
          localStorage.setItem("tok", res?.data?.token);
          //window.location.href = "/";
        }
        ShowNotitication(res.data, id);
        setGetResult(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://message.mv/imgs/MxSMS-con-2.png"
            alt="messagemv"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="username"
                  className="block text-blue-300 py-2 font-bold mb-2"
                >
                  User name
                </label>
                <div className="mt-1">
                  <input
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="username"
                    onChange={(e) =>
                      setval({ ...val, username: e.target.value })
                    }
                    required
                    className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-blue-300 py-2 font-bold mb-2"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) =>
                      setval({ ...val, password: e.target.value })
                    }
                    required
                    className="shadow appearance-none border rounded w-full p-3 text-gray-700 leading-tight focus:ring transform transition hover:scale-105 duration-300 ease-in-out"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="https://message.mv/resetpw"
                    target="_blank"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  onClick={() => handleclick()}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-cyan-400 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                >
                  Sign in
                </button>
              </div>
            </div>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Or{" "}
                    <Link
                      to="/auth/register"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Create new account
                    </Link>
                  </span>
                </div>
              </div>
              <div className="mx-auto">
                <div className="flex mt-4 w-full md:justify-start  fade-in content-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.aximweb.mvsms&hl=en&gl=US"
                    target="_blank"
                  >
                    <img
                      src="https://message.mv/imgs/PlayStore.svg"
                      className="h-12 pr-12 transform hover:scale-125 duration-300 ease-in-out"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
