import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { KeyIcon, UsersIcon } from "@heroicons/react/outline";

const baseURL = process.env.REACT_APP_API_ENDPOINT;

export default function Signup() {
  const [status, setstatus] = useState(false);
  const [recapt, setrecaptcha] = useState(false);
  const recaptchaRef = useRef(null);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  const [query, setQuery] = useState({
    name: "",
    email: "",
    username: "",
    mobile: "",
    password: "",
    otp: "",
    recaptcharesponse: ""
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setQuery((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const ShowNotitication = (data, id) => {
    toast.update(id, {
      render: data?.message ? data?.message : "Something is missing here!!",
      type: data?.success ? "success" : "error",
      isLoading: false,
      autoClose: 2000,
      theme: "colored"
    });
    //if (data.success) window.location.href = "/";
    //toast.dismiss(toastId.current);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setstatus(false);
    setrecaptcha(true);
    const captchaToken = await recaptchaRef.current.executeAsync();

    const data = new FormData();
    query.captchaval = captchaToken;
    data.append("param", JSON.stringify(query));
    const id = toast.loading("Please wait...");
    axios
      .post(`${baseURL}/createUser`, data)
      .then((res) => {
        if (res?.data?.success) {
          window.location.href = "/auth/login";
        }
        ShowNotitication(res.data, id);
      })
      .catch((error) => {
        console.error(error);
      });
    recaptchaRef.current.reset();
  };

  const requestOtp = async () => {
    const data = new FormData();
    query.otpMobile = query.mobile;
    const captchaToken = await recaptchaRef.current.executeAsync();
    query.captchaval = captchaToken;

    data.append("param", JSON.stringify(query));
    const id = toast.loading("Please wait...");
    axios
      .post(`${baseURL}/requestToken`, data)
      .then((res) => {
        if (res?.data?.success) {
          //window.location.href = "/auth/login";
        }
        ShowNotitication(res.data, id);
      })
      .catch((error) => {
        console.error(error);
      });
    recaptchaRef.current.reset();
  };

  const handletimer = () => {
    if (query.mobile === "") {
      return false;
    } else {
      setCounter(80);
      requestOtp();
    }
  };

  return (
    <>
      <div className="min-h-full flex">
        <div className="flex-1 flex flex-col justify-center">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h3 className="mt-2 text-3xl font-extrabold text-center text-gray-900">
                Register new account
              </h3>
            </div>
            <p className="mt-2 text-sm text-gray-600 text-center">
              Or{" "}
              <Link
                to="/auth/login"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Login to your account
              </Link>
            </p>
            <div className="mt-8">
              <div>
                <div className="mt-6 relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-white text-gray-500">
                      Sign up details
                    </span>
                  </div>
                </div>
              </div>

              <div className="mt-2">
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Full Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Your Full Name"
                        onChange={(e) => handleChange(e)}
                        autoComplete="off"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <fieldset>
                      <legend className="block text-sm font-medium text-gray-700">
                        Card Details
                      </legend>
                      <div className="mt-1 bg-white rounded-md shadow-sm -space-y-px">
                        <div>
                          <label htmlFor="card-number" className="sr-only">
                            Card number
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 flex items-center">
                              <label htmlFor="country" className="sr-only">
                                Country
                              </label>
                              <select
                                id="country"
                                name="country"
                                autoComplete="country"
                                className="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
                              >
                                <option>MV</option>
                              </select>
                            </div>
                            <input
                              type="text"
                              id="mobile"
                              name="mobile"
                              required
                              onChange={(e) => handleChange(e)}
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-16 sm:text-sm border-gray-300 rounded-md"
                              placeholder="99***** OR 77*****"
                            />
                          </div>
                        </div>
                        <div className="flex -space-x-px">
                          <div className="w-1/2 flex-1 min-w-0">
                            <label htmlFor="otp" className="sr-only">
                              Enter OTP
                            </label>
                            <input
                              type="text"
                              name="otp"
                              id="otp"
                              onChange={(e) => handleChange(e)}
                              className="focus:ring-indigo-500 focus:border-indigo-500 relative block w-full rounded-none rounded-bl-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                              placeholder="OTP"
                            />
                          </div>
                          <div className="flex-1 min-w-0">
                            <label htmlFor="card-cvc" className="sr-only">
                              CVC
                            </label>
                            <button
                              type="button"
                              disabled={counter > 0 ? true : false}
                              onClick={() => handletimer()}
                              className="focus:ring-indigo-500 hover:text-white focus:border-indigo-500 relative block w-full rounded-none rounded-br-md bg-slate-500 focus:z-10 sm:text-sm border-gray-300 text-white h-full"
                              placeholder="CVC"
                            >
                              {counter > 0 && query.mobile !== ""
                                ? counter
                                : "Request OTP"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div>
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-gray-700"
                    >
                      UserName
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                          <UsersIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="text"
                          name="username"
                          id="username"
                          required
                          onChange={(e) => handleChange(e)}
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                          placeholder="username"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <label
                        htmlFor="company-website"
                        className="block text-sm font-medium text-gray-700"
                      >
                        E-Mail address
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                          @
                        </span>
                        <input
                          id="email"
                          name="email"
                          type="email"
                          onChange={(e) => handleChange(e)}
                          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                          placeholder="me@example.com"
                        />
                      </div>
                      <p
                        className="ml-2 mt-2 text-sm text-gray-500"
                        id="email-description"
                      >
                        We'll NOT use this for spam.
                      </p>
                    </div>
                  </div>

                  <div className="space-y-1">
                    <div>
                      <label
                        htmlFor="search"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1 relative flex items-center">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          onChange={(e) => handleChange(e)}
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                        />
                        <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                          <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                            <KeyIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </kbd>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECATPCHA_SITE_KEY}
                    size="invisible"
                  />
                  <div>
                    {/*  <GoogleReCaptcha
                        onVerify={(token) => {
                          setQuery({
                            ...query,
                            recaptcharesponse: token
                          });
                        }}
                      /> */}

                    <button
                      type="submit"
                      disabled={status}
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Create your account
                    </button>
                  </div>
                </form>

                <p className="mt-5 text-sm text-gray-600 text-center">
                  <a
                    href="https://message.mv/terms"
                    target="_blank"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Terms and Conditions Apply
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
