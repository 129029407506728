async function fetchAPI(url, method = "GET", data = {}, token = "") {
  let res = null;
  var myHeaders = new Headers();

  if (token) myHeaders.append("Authorization", "Bearer " + token);

  if (method === "POST") {
    res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/` + url, {
      method: "POST",
      headers: myHeaders,
      body: data
    });
  }
  if (method === "PUT") {
    res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/` + url, {
      method: "PUT",
      headers: myHeaders,
      body: JSON.stringify(data)
    });
  }
  if (method === "GET") {
    res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/` + url, {
      method: "GET",
      headers: myHeaders
    });
  }

  const json = await res?.json();

  if (json.errors) {
    return json.errors[0].message;
    return;
  }
  return json;
}

const token = localStorage.getItem("tok");

export async function getuserprofile(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  const data = fetchAPI("getuserprofile", "POST", formdata, token);
  return data;
}

export async function saveNewcase(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("file", param.file);
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("savecase", "POST", formdata, token);
  return data;
}

export async function getcases(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("getcases", "POST", formdata, token);
  return data;
}

export async function getcasesbyid(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("getcasesbyid", "POST", formdata, token);
  return data;
}

export async function savecasenote(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("savecasenotes", "POST", formdata, token);
  return data;
}

export async function getcasenotes(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("getcasenotes", "POST", formdata, token);
  return data;
}

export async function uploadattestdocs(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  for (const file of param.files) {
    formdata.append("file[]", file, file.name);
  }
  formdata.append("param", JSON.stringify(param.param));
  const data = fetchAPI("uploadattestdocs", "POST", formdata, token);
  return data;
}

export async function getuserattestdocs(param) {
  const data = fetchAPI(
    "getuserattestdocs",
    "POST",
    JSON.stringify(param),
    token
  );
  return data;
}

export async function getuserattestationbyid(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("getuserattestationbyid", "POST", formdata, token);
  return data;
}

export async function sendMessage(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("sendMessage", "POST", formdata, token);
  return data;
}

export async function getTransactions(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("gettransactions", "POST", formdata, token);
  return data;
}

export async function getsessions(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("getsessions", "POST", formdata, token);
  return data;
}

export async function acceptTermsandcondition(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("param", JSON.stringify(param));
  const data = fetchAPI("acceptTermsandcondition", "POST", formdata, token);
  return data;
}

export async function logout(param) {
  const data = fetchAPI("logout", "POST", {}, token);
  return data;
}

export async function pogetpoitems(param) {
  const data = fetchAPI("po-getpoitems", "POST", JSON.stringify(param));
  return data;
}

export async function updatedeliveryaddress(param) {
  const data = fetchAPI(
    "po-updatedeliveryaddress",
    "POST",
    JSON.stringify(param)
  );
  return data;
}

export async function getvendormail(param) {
  const data = fetchAPI(
    "getvendormail",
    "POST",
    JSON.stringify({
      rowdata: param
    })
  );
  return data;
}

export async function towedVehicles(param) {
  var formdata = require("form-data");
  var formdata = new FormData();
  formdata.append("lang", param.lang);
  formdata.append("regno", param.regno);
  formdata.append("idcard", param.idcard);
  formdata.append("recaptcha", param.recatpcherKey);
  const data = fetchAPI("mobile/olservices/gettowvehicles", "POST", formdata);
  return data;
}

export async function getwantedlist() {
  const data = fetchAPI("mobile/case/getwantedlist", "LIST_POST", {});
  return data;
}
