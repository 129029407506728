import React, { useContext, useRef, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import UserInfo from "../components/UserInfo";
import {
  acceptTermsandcondition,
  getuserprofile,
  sendMessage
} from "../lib/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AppContext } from "../context/AppContext";
import { PhoneOutgoingIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const textTypes = [
  { name: "Text", enabled: true, type: 0 },
  { name: "Flash", enabled: true, type: 1 },
  { name: "Wap Push", enabled: true, type: 4 }
];

export default function NewMessage() {
  const [schedule, setSchedule] = useState(false);
  const [textType, settextType] = useState(textTypes[0]);
  const [isLoading, setloading] = useState(false);
  const { state, dispatch } = useContext(AppContext);

  const [query, setQuery] = useState({
    receiver: "",
    sendername: "",
    messageText: "",
    type: textType?.type,
    unicodeText: "",
    wapPushinput: "",
    date: "",
    scheduletime: "",
    schedule: schedule
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "date") {
      setQuery((prevState) => ({
        ...prevState,
        date: moment(value).format("MM/DD/YYYY"),
        scheduletime: moment(value).format("hh:mm A")
      }));
    } else {
      setQuery((prevState) => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const [smslimit, setLimit] = useState({
    Limit: "",
    count: 0,
    unicode: ""
  });

  const countMessage = (e) => {
    const unicode = e.target.value.replace(/[\s\S]/g, function (escape) {
      return ("0000" + escape.charCodeAt().toString(16)).slice(-4);
    });
    setLimit({
      count: e.target.value.length,
      limit: "More than 1 message",
      unicode: unicode
    });
  };

  const updateCredit = async () => {
    const response = await getuserprofile({});
    const resp = await response;
    if (resp) {
      dispatch({
        type: "LOGGEDIN_USER",
        response: resp
      });
    }
  };

  const sendsms = async (e) => {
    e.preventDefault();
    if (textType?.type !== 4) query.wapPushinput = "";

    query.schedule = schedule;

    const toastId = toast.loading("Please wait...");
    setloading(true);
    const response = await sendMessage(query);
    const resp = await response;
    if (resp.success) {
      setQuery({
        receiver: "",
        sendername: "",
        messageText: "",
        type: textType?.type,
        unicodeText: "",
        wapPushinput: "",
        date: "",
        schedule: schedule
      });
      setSchedule(false);
      updateCredit();
    }
    toast.update(toastId, {
      render: resp?.message ? resp?.message : "Something is missing here!!",
      type: resp?.success ? "success" : "error",
      isLoading: false,
      autoClose: 2000,
      theme: "colored"
    });
    setloading(false);
  };

  const acceptTerms = async () => {
    const toastId = toast.loading("Please wait...");
    setloading(true);
    const response = await acceptTermsandcondition(query);
    const resp = await response;
    if (resp?.success) {
      window.location.href = "/newmessage";
    }
    toast.update(toastId, {
      render: resp?.message ? resp?.message : "Something is missing here!!",
      type: resp?.success ? "success" : "error",
      isLoading: false,
      autoClose: 2000,
      theme: "colored"
    });
    setloading(false);
  };

  return (
    <>
      <div className="py-4 pl-3 pr-3">
        <div className="w-full">
          <UserInfo />

          <div className="mt-5 md:mt-0 md:col-span-2">
            {state?.user?.terms === "N" && (
              <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
                <div className="max-w-max mx-auto">
                  <main className="sm:flex">
                    <div className="sm:ml-6">
                      <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                        <h1 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                          Accept Terms and Conditions
                        </h1>
                        <p className="mt-1 text-base text-gray-500">
                          To send SMS You must Accept Terms and Conditions
                        </p>
                      </div>
                      <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                        <button
                          onClick={acceptTerms}
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Accept Terms
                        </button>
                        <a
                          href="/terms/index.html"
                          target="_blank"
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Read Terms
                        </a>
                      </div>
                    </div>
                  </main>
                </div>
              </div>
            )}
            <div>
              {state?.user?.terms === "Y" && (
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div>
                      <div className="text-center">
                        <RadioGroup
                          value={textType}
                          onChange={settextType}
                          className="mt-2"
                        >
                          <RadioGroup.Label className="sr-only">
                            Choose Messaging type
                          </RadioGroup.Label>
                          <div className="grid grid-cols-3 gap-3 sm:grid-cols-6">
                            {textTypes.map((option) => (
                              <RadioGroup.Option
                                key={option.name}
                                value={option}
                                className={({ active, checked }) =>
                                  classNames(
                                    option.enabled
                                      ? "cursor-pointer focus:outline-none"
                                      : "opacity-25 cursor-not-allowed",
                                    active
                                      ? "ring-2 ring-offset-2 ring-indigo-500"
                                      : "",
                                    checked
                                      ? "bg-indigo-600 border-transparent text-white hover:bg-indigo-700"
                                      : "bg-white border-gray-200 text-gray-900 hover:bg-gray-50",
                                    "border rounded-md py-3 px-3 flex items-center justify-center text-sm font-medium uppercase sm:flex-1"
                                  )
                                }
                                disabled={!option.enabled}
                              >
                                <RadioGroup.Label as="span">
                                  {option.name}
                                </RadioGroup.Label>
                              </RadioGroup.Option>
                            ))}
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                    <div>
                      <div className="mt-1">
                        <div>
                          <label
                            htmlFor="receiver"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Receipts (Mobile numbers to send SMS)
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={2}
                              name="receiver"
                              id="receiver"
                              placeholder="Numbers without country code"
                              value={query.receiver}
                              onChange={(e) => handleChange(e)}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Commar seperated number(s) Max. 250000 Numbers
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <label
                        htmlFor="sendername"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Sender Name
                      </label>
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                          <PhoneOutgoingIcon
                            className="flex-shrink-0 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                        <input
                          type="text"
                          name="sendername"
                          id="sendername"
                          value={query.sendername}
                          onChange={(e) => handleChange(e)}
                          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                          placeholder="Any name or number"
                        />
                      </div>
                    </div>
                    {textType?.type === 4 && (
                      <div>
                        <label
                          htmlFor="wapPushinput"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Wap Push URL
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                            url
                          </span>
                          <input
                            type="text"
                            name="wapPushinput"
                            id="wapPushinput"
                            value={query.wapPushinput}
                            onChange={(e) => handleChange(e)}
                            className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                            placeholder="https://message.mv"
                          />
                        </div>
                      </div>
                    )}
                    <div>
                      <div className="mt-1">
                        <div>
                          <label
                            htmlFor="messageText"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Add your message
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={4}
                              onChange={(e) => countMessage(e)}
                              name="messageText"
                              id="messageText"
                              onKeyUp={(e) => handleChange(e)}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={query.messageText}
                              placeholder="Message Text"
                            />
                          </div>
                          <p className="mt-2 text-sm text-gray-500">
                            Characters: {smslimit.count}/160{" "}
                            {smslimit.count > 160
                              ? parseInt(smslimit.count / 160 + 1) + " Messages"
                              : "1 Message"}
                          </p>
                        </div>
                      </div>
                    </div>
                    <fieldset className="space-y-5">
                      <legend className="sr-only">Schedule</legend>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="schedule"
                            aria-describedby="schedule-description"
                            name="schedule"
                            type="checkbox"
                            value={schedule}
                            onChange={() => setSchedule(!schedule)}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label
                            htmlFor="schedule"
                            className="font-medium text-gray-700"
                          >
                            Schedule Message
                          </label>
                          <p
                            id="schedule-description"
                            className="text-gray-500"
                          >
                            Schedule Time must be 30 mins from the date of
                            schedule
                          </p>
                        </div>
                      </div>
                    </fieldset>
                    {schedule && (
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </div>
                        <input
                          type="datetime-local"
                          id="date"
                          name="date"
                          onChange={(e) => handleChange(e)}
                          className="bg-gray-50 border border-gray-300 text-black sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:text-gray-800 dark:focus:ring-blue-500 dark:focus:border-blue-500 placeholder:text-black"
                          placeholder="Select schedule date time"
                        />
                      </div>
                    )}
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="button"
                      disabled={isLoading}
                      onClick={sendsms}
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
    </>
  );
}
