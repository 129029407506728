import React, { createContext, useReducer } from 'react';
import { appReducer } from '../reducer/appReducer';

export const AppContext = createContext();

export const AppProvider = (props) => {

    const initialState = {
        user: {},
    };

    const [state, dispatch] = useReducer(appReducer, initialState);

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {props.children}
        </AppContext.Provider>
    )
}